@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.piano-container{
  margin-top: 4rem;
  display: flex;
  justify-content: center;
}

.key {
  background-color: white;
  height: 300px;
  width: 60px;
  border:2px solid black;
  display: flex;
  justify-content: center;
}

.key-text {
  position: relative;
  color: black;
  font-weight: 600;
  font-size: 36px;
  margin-top: 240px;
  pointer-events: none;
}

.key.flat {
  position: relative;
  background-color: black;
  margin-left: -17px;
  margin-right: -17px;
  height: 200px;
  width:30px;
  z-index: 2;
}

.key.pressed, .key.flat.pressed {
  /* background-color: #00d8ff; */
  background-color: lightpink;

}

.button {
  background-color: white;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: .5rem;
  border-radius: 5px;
  border: black solid 1px;
  color: black;
}

.button-flat {
  background-color: black;
  position: relative;
  top: 170px;
  height: 23px;
  width: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  border: white solid 1px;
  color: white;

}

.piano {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

.instructionKeys {
  display: flex;
}

.box {
  height: 1rem;
  width: 1rem;
}

.instructions {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {

    .piano {
      width: 95vw;
      margin: auto;
      justify-content: center;
    }

    .instructions {
      text-align: center;
      width: 70vw;
      margin: 2rem auto;
    }
}

/* Landscape */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {
.piano {
      width: 95vw;
      margin: auto;
      justify-content: center;
    }

    .instructions {
      text-align: center;
      width: 70vw;
      margin: 1rem auto;
    }
}


/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

    .piano {
      width: 95vw;
      margin: auto;
    }

    .instructions {
      text-align: center;
    }
}

/* Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

    .piano {
      margin: auto;
      justify-content: center;
    }

    .bump {
      position: relative;
      bottom: 12vh;
    }

    .bump-more {
      position: relative;
      bottom: 14vh;
    }

}

.navbar {
  display: flex;
  position: relative;
  background-color: lightgoldenrodyellow;
  width: 100%;
  top: 0;
  padding: 1rem;
  height: 13vh;
}

.half{
  width: 50%;
  display: flex;
}

.justify-right{
  justify-content: right;
}

.navbar-items{
  margin-right: 3vw;
}

.navbar-items:hover{
  cursor: pointer;
  text-decoration: underline;
}

.pac{
  font-family: 'Pacifico';
}

.test {
 font-family: 'Pacifico';
}

.justify-left {
  justify-content: left;
}

.mb-3 {
  margin-bottom: 3rem;
}
.mb-2 {
  margin-bottom: 2rem;
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {

    .navbar {
      height: 11vh;
    }

    * {
      font-size: 1.3rem;
    }

    .smaller-bump {
      position: relative;
      bottom:1vh;
    }
}

/* Landscape */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {

    * {
      font-size: 1.3rem;
    }
    .smaller-bump {
      position: relative;
      bottom:2vh;
    }
}


/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

    .navbar {
      height: 16vh;
    }

    .bump-up {
      position: relative;
      bottom: 3vh;
    }

    .slight-bump {
      position: relative;
      bottom: 1vh;
    }

    .smaller-bump {
      position: relative;
      bottom: 3vh;
    }

    .navbar-items {
      position: relative;
      bottom:.5rem;
    }
}

/* Portrait */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

    .navbar {
      height: 11vh;
      /* width: 100vw; */
    }

    .slight-bump {
      position: relative;
      bottom: 1vh;
    }

    .smaller-bump {
      position: relative;
      bottom: 2vh;
    }


}

.rob {
  font-family: 'Roboto';
}

.background {
  /* background-image: url("./../assets/landing1.jpg");
  background-size: cover;
  background-repeat: no-repeat; */
  display: flex;
  justify-content: center ;
  height: 87vh;
  /* max-width: 1500px; */
  text-align: center;
}

.landing-container {
  /* text-align: left; */
  text-align: center;
  position: relative;
  top: 3rem;
  /* background-color: rgba(255,255,255, .7); */
  background-color: rgb(250, 250, 210, 1);
  width: 30%;
  height: 24rem;
  border-radius: 13px;
  margin-right: 10%;
  /* padding-left: 5rem; */
}

.landing-info {
  width: 30%;
  margin-top: 2rem;
}

.mt-2 {
  margin-top: 5rem;
}

.title {
  position: relative;
  top: 2rem;
  /* right: 1rem; */
  font-size: 6rem;
}

.sub-title {
  position: relative;
  top: 1.5rem;
}

.get-started {
  /* background-color: lightgoldenrodyellow; */
  background-color: white;
  border-radius: 30px;
  padding: .75rem 1rem;
  border: 2px solid white;
  position: relative;
  top: 7rem;
  transition: ease-in-out all 0.2s;
  box-shadow: lightpink 0 0 3px;

}

.get-started:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: ease-in-out all 0.2s;
  box-shadow: lightpink 1px 1px 4px;
  /* background-color: rgba(255,255,255, 0); */
  background-color: lightpink;
}

.row {
  display: flex;
}

.circle {
  height: 130px;
  width: 130px;
  border: solid lightgoldenrodyellow 5px;
  border-radius: 50%;
  background-size: cover;


}

.img-1 {
  background-image: url(/static/media/react.4e9bc624.jpg);
  background-position: center;
  background-size: 240%;
}

.img-2 {
  background-image: url(/static/media/keyboard.35cf4547.jpg);
  background-position: center;

}

.img-3 {
  background-image: url(/static/media/kushol-h.eacc0b1b.jpg);
  background-position: 35% 0;

}

.circles {
  justify-content: space-around;
  position: relative;
  /* right: 1rem; */
}



.info {
  margin-top: 1rem;
}

.flex {
  display: flex;
  justify-content: center;
}

.landing-gif {
  background-image: url(/static/media/piano-demo.a9af6e43.gif);
  height: 15rem;
  width: 20rem;
  background-size: contain;
  background-repeat: no-repeat;
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {

    .background {
      flex-direction: column;
      width: 100vw;
    }

    .landing-container {
      width: 100%;
      top: 15vh;
      margin: auto;
      height: 60rem;
    }

    .get-started {
      padding: .75rem 1rem;
      top: 0;
      margin: 2rem;

    }

    .title {
      position: relative;
      top: 2rem;
      /* right: 1rem; */
      font-size: 6rem;
      margin-bottom: 2.5rem;
    }

    .sub-title {
      position: relative;
      top: 1.5rem;
    }
    .landing-info {
      width: 90%;
      margin-top: 2rem;
      margin-bottom: 1rem;
      position: relative;
      top: 20vh;
      margin: 1rem auto 3rem auto;
    }
}

/* Landscape */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {

    .landing-container{
      width: 43vw;
    }

    .title {
      position: relative;
      top: 1rem;
      /* right: 1rem; */
      font-size: 6rem;
      margin-bottom: 2.5rem;
    }
    .sub-title {
      position: relative;
      top: .5rem;
    }
    .get-started {
      padding: .75rem 1rem;
      top: 0;
      margin: 2rem;
    }

}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2) {
    body {
          font-size: 1.3rem;
        }
}

/* Portrait */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .background {
      flex-direction: column;
      width: 100vw;
    }

    .landing-container {
      width: 100%;
      top: 45vh;
      margin: auto;
      height: 60rem;
    }

    .get-started {
      padding: .75rem 1rem;
      top: 0;
      margin: 2rem;

    }


    .title {
      position: relative;
      top: 2rem;
      /* right: 1rem; */
      font-size: 6rem;
      margin-bottom: 2.5rem;
    }

    .sub-title {
      position: relative;
      top: 1.5rem;
    }

    .landing-info {
      width: 90%;
      margin-top: 2rem;
      position: relative;
      top: 55vh;
      margin: auto;
    }

    .mb-mobile {
      margin-bottom: 2rem;
    }
}

/* Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

    .background {
      flex-direction: column;
      width: 100vw;
    }

    .landing-container {
      width: 100%;
      top: 75vh;
      margin: auto;
      height: 60rem;
    }

    .get-started {
      padding: .75rem 1rem;
      top: 0;
      margin: 2rem;
    }

    .title {
      position: relative;
      top: 1rem;
      /* right: 1rem; */
      font-size: 6rem;
      margin-bottom: 2.5rem;
    }

    .sub-title {
      position: relative;
      top: .5rem;
    }

    .landing-info {
      width: 90%;
      margin-top: 2rem;
      position: relative;
      top: 80vh;
      margin: auto;
    }

    .mb-mobile {
      margin-bottom: 2rem;
    }

    .get-started {
      margin: 1rem;
    }

}

