.key {
  background-color: white;
  height: 300px;
  width: 60px;
  border:2px solid black;
  display: flex;
  justify-content: center;
}

.key-text {
  position: relative;
  color: black;
  font-weight: 600;
  font-size: 36px;
  margin-top: 240px;
  pointer-events: none;
}

.key.flat {
  position: relative;
  background-color: black;
  margin-left: -17px;
  margin-right: -17px;
  height: 200px;
  width:30px;
  z-index: 2;
}

.key.pressed, .key.flat.pressed {
  /* background-color: #00d8ff; */
  background-color: lightpink;

}

.button {
  background-color: white;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: .5rem;
  border-radius: 5px;
  border: black solid 1px;
  color: black;
}

.button-flat {
  background-color: black;
  position: relative;
  top: 170px;
  height: 23px;
  width: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  border: white solid 1px;
  color: white;

}
