@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap');

.rob {
  font-family: 'Roboto';
}

.background {
  /* background-image: url("./../assets/landing1.jpg");
  background-size: cover;
  background-repeat: no-repeat; */
  display: flex;
  justify-content: center ;
  height: 87vh;
  /* max-width: 1500px; */
  text-align: center;
}

.landing-container {
  /* text-align: left; */
  text-align: center;
  position: relative;
  top: 3rem;
  /* background-color: rgba(255,255,255, .7); */
  background-color: rgb(250, 250, 210, 1);
  width: 30%;
  height: 24rem;
  border-radius: 13px;
  margin-right: 10%;
  /* padding-left: 5rem; */
}

.landing-info {
  width: 30%;
  margin-top: 2rem;
}

.mt-2 {
  margin-top: 5rem;
}

.title {
  position: relative;
  top: 2rem;
  /* right: 1rem; */
  font-size: 6rem;
}

.sub-title {
  position: relative;
  top: 1.5rem;
}

.get-started {
  /* background-color: lightgoldenrodyellow; */
  background-color: white;
  border-radius: 30px;
  padding: .75rem 1rem;
  border: 2px solid white;
  position: relative;
  top: 7rem;
  transition: ease-in-out all 0.2s;
  box-shadow: lightpink 0 0 3px;

}

.get-started:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: ease-in-out all 0.2s;
  box-shadow: lightpink 1px 1px 4px;
  /* background-color: rgba(255,255,255, 0); */
  background-color: lightpink;
}

.row {
  display: flex;
}

.circle {
  height: 130px;
  width: 130px;
  border: solid lightgoldenrodyellow 5px;
  border-radius: 50%;
  background-size: cover;


}

.img-1 {
  background-image: url("../assets/react.jpg");
  background-position: center;
  background-size: 240%;
}

.img-2 {
  background-image: url("../assets/keyboard.jpg");
  background-position: center;

}

.img-3 {
  background-image: url("../assets/kushol-h.jpg");
  background-position: 35% 0;

}

.circles {
  justify-content: space-around;
  position: relative;
  /* right: 1rem; */
}



.info {
  margin-top: 1rem;
}

.flex {
  display: flex;
  justify-content: center;
}

.landing-gif {
  background-image: url("../assets/piano-demo.gif");
  height: 15rem;
  width: 20rem;
  background-size: contain;
  background-repeat: no-repeat;
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {

    .background {
      flex-direction: column;
      width: 100vw;
    }

    .landing-container {
      width: 100%;
      top: 15vh;
      margin: auto;
      height: 60rem;
    }

    .get-started {
      padding: .75rem 1rem;
      top: 0;
      margin: 2rem;

    }

    .title {
      position: relative;
      top: 2rem;
      /* right: 1rem; */
      font-size: 6rem;
      margin-bottom: 2.5rem;
    }

    .sub-title {
      position: relative;
      top: 1.5rem;
    }
    .landing-info {
      width: 90%;
      margin-top: 2rem;
      margin-bottom: 1rem;
      position: relative;
      top: 20vh;
      margin: 1rem auto 3rem auto;
    }
}

/* Landscape */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {

    .landing-container{
      width: 43vw;
    }

    .title {
      position: relative;
      top: 1rem;
      /* right: 1rem; */
      font-size: 6rem;
      margin-bottom: 2.5rem;
    }
    .sub-title {
      position: relative;
      top: .5rem;
    }
    .get-started {
      padding: .75rem 1rem;
      top: 0;
      margin: 2rem;
    }

}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2) {
    body {
          font-size: 1.3rem;
        }
}

/* Portrait */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .background {
      flex-direction: column;
      width: 100vw;
    }

    .landing-container {
      width: 100%;
      top: 45vh;
      margin: auto;
      height: 60rem;
    }

    .get-started {
      padding: .75rem 1rem;
      top: 0;
      margin: 2rem;

    }


    .title {
      position: relative;
      top: 2rem;
      /* right: 1rem; */
      font-size: 6rem;
      margin-bottom: 2.5rem;
    }

    .sub-title {
      position: relative;
      top: 1.5rem;
    }

    .landing-info {
      width: 90%;
      margin-top: 2rem;
      position: relative;
      top: 55vh;
      margin: auto;
    }

    .mb-mobile {
      margin-bottom: 2rem;
    }
}

/* Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

    .background {
      flex-direction: column;
      width: 100vw;
    }

    .landing-container {
      width: 100%;
      top: 75vh;
      margin: auto;
      height: 60rem;
    }

    .get-started {
      padding: .75rem 1rem;
      top: 0;
      margin: 2rem;
    }

    .title {
      position: relative;
      top: 1rem;
      /* right: 1rem; */
      font-size: 6rem;
      margin-bottom: 2.5rem;
    }

    .sub-title {
      position: relative;
      top: .5rem;
    }

    .landing-info {
      width: 90%;
      margin-top: 2rem;
      position: relative;
      top: 80vh;
      margin: auto;
    }

    .mb-mobile {
      margin-bottom: 2rem;
    }

    .get-started {
      margin: 1rem;
    }

}
